import { useRouteError } from "react-router-dom";
interface IError {
  statusText?: string;
  message?: string;
  stack?: string;
}

export default function ErrorPage() {
  const error = useRouteError() as IError;
  console.error(error);

  return (
    <div id="error-page" style={{ textAlign: "center" }}>
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        <i>{error.statusText || error.message}</i>
        <br />
        {error.stack && (
          <textarea name="error-area" id="error_area" rows={10} readOnly value={error.stack} style={{ width: "80%" }} />
        )}
      </p>
    </div>
  );
}
