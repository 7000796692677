import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Mousewheel, Pagination } from "swiper";
import Attract from "../../components/attract/Attract";

export default function Home() {
  return (
    <>
      <Swiper
        direction={"vertical"}
        slidesPerView={1}
        spaceBetween={30}
        mousewheel={true}
        modules={[Mousewheel, Pagination]}
        className="mySwiper"
      >
        <SwiperSlide>
          <Attract />
        </SwiperSlide>
        {/*
          <SwiperSlide>
          <MobileExplain />
        </SwiperSlide>
        <SwiperSlide>
          <Feature />
        </SwiperSlide>
        <SwiperSlide>
          <DashboardExplain />
        </SwiperSlide>
        <SwiperSlide>
          <Contact />
        </SwiperSlide>
        */}
      </Swiper>
    </>
  );
}
