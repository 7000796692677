import ErrorPage from "./views/ErrorPage";
import Home from "./views/home/Home";
import "./sass/App.scss";
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import MobileExplain from "./views/mobile-explain/MobileExplain";
import Policy from "./views/policy/Policy";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="*" element={<ErrorPage />} />
        <Route path="/" element={<Home />} />
        <Route path="/policy" element={<Policy />} />
        <Route path="/subscribe" element={<MobileExplain />} />
      </Routes>
    </Router>
  );
};

export default App;
