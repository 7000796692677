import Logo from "../logo/Logo";
import React, { useState } from "react";
import { Resolver, useForm } from "react-hook-form";
import ContactServices from "../../services/api/contact.services";
import { Link, useNavigate } from "react-router-dom";
import { Alert, Snackbar } from "@mui/material";
import { Loader } from "../loader/Loader";
import isEmpty from "../../utils/isempty.utils";

type FormValuesContact = {
  email: string;
};

const resolver: Resolver<FormValuesContact> = async (values) => {
  return {
    values: values.email ? values : {},
    errors: !values.email
      ? {
          email: {
            type: "required",
            message: "rentrer votre email.",
          },
        }
      : {},
  };
};

const Attract = () => {
  const [sendMail, setSendMail] = useState(false);
  const navigate = useNavigate();
  const [openFailed, setOpenFailed] = useState(false);
  const [openCreated, setOpenCreated] = useState(false);
  const [message, setMessage] = useState("");
  const [isloaded, setIsloaded] = useState(false);
  const [messageErrorMail, setMessageErrorMail] = useState<any>("");
  const data = {
    size: 200,
  };

  function validateEmail(input: string): string {
    const regex = new RegExp("^[a-zA-Z0-9.!#$%&’+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)$");
    if (regex.test(input)) {
      return "";
    } else {
      return "Adresse e-mail invalide.";
    }
  }
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValuesContact>({ resolver });

  const onSubmit = handleSubmit((data) => {
    setIsloaded(true);
    if (validateEmail(data.email) != "Adresse e-mail invalide.") {
      ContactServices.create(data)
        .then((response) => {
          console.log(response);
          if (response.status === 201) {
            if (response.data.status === 400) {
              setIsloaded(false);
              setMessageErrorMail(response.data.message);
            } else {
              navigate("/subscribe");
            }
          }
        })
        .catch((err: any) => {
          console.log(err.response.data.message);
          if (err.response.data.message === "Mail already exists") {
            setMessageErrorMail(err.response.data.message);
          }
          setIsloaded(false);
          setOpenFailed(true);
          setMessage("Votre demande n'a pas abouti");
          setTimeout(() => {
            setOpenFailed(false);
          }, 4000);
        });
    } else {
      setIsloaded(false);
      setMessageErrorMail("Adresse e-mail invalide.");
    }
  });

  const displayMail = () => {
    setSendMail(!sendMail);
  };

  const handleCloseSnackbar = (event: any, reason: any) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenFailed(false);
  };

  return (
    <>
      <div className="attract">
        <Logo position={""} />
        <div className="attract-body">
          <div className="left-container">
            <div className="subtitle-attract-container">WeWorkStudio</div>
            <div className="title-attract-container">
              Share<span>Mee</span>
            </div>
            <div className="paragraph-attract-container">
              ShareMe est une application de partage de carte permettant à des particuliers comme à des professionnelles
              de pouvoir échanger rapidement leur coordonnées ainsi que leur réseaux sociaux. L’objectif étant un
              échange rapide d’informations entre deux personnes. Les professionnels pourront bénéficier via un
              abonnement à des avantages supplémentaire.
            </div>
            {!sendMail && (
              <div className="button-container">
                <img src="/assets/logo/appstore.png" className="img-left" />
                <img src="/assets/logo/playstore.png" className="img-left" />
              </div>
            )}
          </div>
          <div className="right-container">
            <div className="background"></div>
            <img src="./assets/images/mockup-attract.png" alt="" />
          </div>
        </div>
        <div className="footer">
          <Link to={"/policy"} style={{ textDecoration: "none", cursor: "pointer" }}>
            <div className="title-footer">Mentions Légales</div>
          </Link>
          <a href="https://weworkstudio.fr/" style={{ textDecoration: "none" }}>
            <div className="title-footer">© Weworkstudio 2023</div>
          </a>
        </div>
      </div>
      <Snackbar open={openCreated} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert severity="success" sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
      <Snackbar open={openFailed} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert severity="error" sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Attract;
