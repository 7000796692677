import Logo from "../../components/logo/Logo";
import { useNavigate } from "react-router-dom";

const MobileExplain = () => {
  const navigate = useNavigate();
  const redirectHome = () => {
    navigate("/");
  };
  return (
    <div className="explain">
      <div onClick={() => redirectHome()}>
        <Logo position={""} />
      </div>
      <div className="explain-body">
        <img src="./assets/images/mockup-explain.png" alt="" />
        <div className="title-explain">Merci de participer a l&#39;aventure avec nous! </div>
        <div className="description">
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          Vous allez recevoir un mail comportant vos identifiant de login a la beta ferme ! N'hésitez pas a nous faire
          vos retour de application !
        </div>
      </div>
    </div>
  );
};

export default MobileExplain;
