import Logo from "../../components/logo/Logo";
import { useNavigate } from "react-router-dom";

const Policy = () => {
  const navigate = useNavigate();

  const arrayPolicy = [
    {
      title: "Introduction",
      description:
        "Bienvenue sur ShareMee. Merci d'avoir choisi notre application pour gérer vos cartes de contact et générer des QR Codes. En utilisant notre service, vous acceptez ces conditions d'utilisation et notre politique de confidentialité.",
    },
    {
      title: "Description du Service",
      description:
        "ShareMee est conçue pour faciliter la génération de QR Codes à partir d'informations personnelles des utilisateurs, telles que des liens vers des réseaux sociaux ou des V_cards contenant des numéros de téléphone, adresses, emails, et autres.",
    },
    {
      title: "Inscription et Compte",
      description:
        "Pour utiliser ShareMee, il peut être nécessaire de créer un compte. L'utilisateur est responsable de la sécurité de son compte et doit nous informer immédiatement de tout accès non autorisé.",
    },
    {
      title: "Intégration des Plateformes Externes",
      description:
        "Stockage : Les données des utilisateurs sont conservées de manière sécurisée sur nos serveurs via une API dédiée.\n" +
        "Vente de Données : Nous pouvons vendre certaines données, telles que les adresses email et numéros de téléphone, à des tiers partenaires. En utilisant ShareMee, vous acceptez cette utilisation de vos données.\n" +
        "\tSécurité : Nous mettons en œuvre des mesures de sécurité pour protéger vos données, mais ne pouvons garantir une sécurité absolue.\n",
    },
    {
      title: "Droits des Utilisateurs en vertu du RGPD",
      description:
        "Tous les utilisateurs résidant dans l'Union Européenne ont le droit d'accéder, rectifier, supprimer leurs données, ou s'opposer à leur traitement. Ces demandes peuvent être adressées à sharemee.pro@gmail.com.",
    },
    {
      title: "Transactions et Paiements",
      description:
        "Des options premium peuvent nécessiter un paiement. Toutes les transactions sont sécurisées, et les détails exacts des offres premium ainsi que la politique de remboursement seront fournis au moment de l'achat.",
    },
    {
      title: "Modifications",
      description:
        "ShareMee se réserve le droit de modifier ou d'ajuster ces conditions à tout moment. Nous informerons nos utilisateurs de tout changement majeur, mais il est de leur responsabilité de vérifier régulièrement ces conditions.",
    },
    {
      title: "Limitation de Responsabilité",
      description:
        "ShareMee n'est pas responsable des dommages indirects, consécutifs ou spéciaux résultant de l'utilisation ou de l'incapacité d'utiliser notre service.",
    },
    {
      title: "Loi Applicable et Juridiction",
      description:
        "Ces conditions sont régies par les lois de la France. Tout litige sera soumis aux tribunaux compétents de la France.",
    },
    {
      title: "Contactez-nous",
      description:
        "Pour toute question, suggestion, ou préoccupation liée à ces conditions, n'hésitez pas à nous contacter à sharemee.pro@gmail.com.",
    },
  ];

  const redirectHome = () => {
    navigate("/");
  };

  return (
    <div className="view--policy">
      <div onClick={() => redirectHome()}>
        <Logo position={""} />
      </div>
      <div className="title-first">Mentions Légales</div>
      {arrayPolicy.map((value, index) => (
        <div key={index} className="container-description">
          <div className="title-part">{value.title}</div>
          <div className="description-part">{value.description}</div>
        </div>
      ))}
    </div>
  );
};

export default Policy;
