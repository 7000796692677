import { LogoProps } from "../../models/position.model";
import { useNavigate } from "react-router-dom";

const Logo = (props: LogoProps) => {
  const { position } = props;

  const LogoToPosition = () => {
    const navigate = useNavigate();

    if (position === "right") {
      return (
        <div className="logo right">
          <div className="logo-container">
            <img src="./assets/logo/logo-app.png" alt="" />
            <div className="title">ShareMee</div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="logo ">
          <div className="logo-container">
            <img src="./assets/logo/logo-app.png" alt="" />
            <div className="title">ShareMee</div>
          </div>
        </div>
      );
    }
  };

  return LogoToPosition();
};

export default Logo;
