import http from "../http-common";
import { ContactRequest } from "../../models/contact.model";

const create = (data: ContactRequest) => {
  return http.post<any>("/create", data);
};

const ContactService = {
  create,
};

export default ContactService;
